import eventsHandler from '@/services/common/eventsHandler.js'

class cashDeskForm extends eventsHandler {
  constructor() {
    super()
    this._cashDesk = {}
    this._valid = false
  }

  get cashDesk() {
    const cashDesk = {
      ...this._cashDesk
    }
    return cashDesk
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._cashDesk.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetCashDesk() {
    this._cashDesk = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {cashDesk: {}})
  }

  updateField(key, value) {
    this._cashDesk[key] = value
    this._executeCallbacksOf('update', {cashDesk: this._cashDesk})
    this._executeCallbacksOf('update-' + key, {key: this._cashDesk[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(cashDesk) {
    this._cashDesk = cashDesk
    this._executeCallbacksOf('update', {cashDesk: this._cashDesk})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new cashDeskForm()