<template>
  <FullScreenDialog
    v-model="cashDeskDialog"
    title="Cassa"
    :routeFather="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in cashDesksTab"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in cashDesksTab" :key="tabItem.key">
          <v-card flat>
            <v-card-text class="pa-0">
              <component :is="tabItem.component"></component>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="closeDialog()"
        >Annulla</v-btn>
        <v-btn
          text
          color="default"
          :disabled="!formValid || isLicenseBlocked"
          @click="submitForm"
        >Salva</v-btn>
      </div>
    </template>

<!-- KEEP COMMENT - ONEWARE-562    
   <template v-slot:header-actions>
      <div>
        <StandardMenu
          close-on-content-click
        >
          <template v-slot:activator="{on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Stampa</v-list-item-content>
              </v-list-item>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon color="error">mdi-backspace-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="error--text">Resetta</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </StandardMenu>
      </div>
    </template> -->
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from '@/components/common/StandardMenu.vue';
import GeneralForm from "@/components/areas/payments/cashDesks/form/GeneralFormTab.vue"
import cashDesksService from '@/services/cashDesks/cashDesks.service.js'
import cashDeskForm from '@/services/cashDesks/cashDesk.form.js'

export default {
  name: "CashDesksEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    GeneralForm
  },
  data: function () {
    return {
      id: undefined,
      tab: null,
      formValid: false,
      loadingCashDesk: false,
      cashDeskDialog: this.openDialog,
      cashDesksTab: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm"
        }
      ],
      cashDesk: undefined,
      originalUrl: window.location.pathname,
      isLicenseBlocked: undefined
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    this.id = this.$route.params.id
    if(this.id) {
      this.fetchCashDesk()
    }


    let self = this
    cashDeskForm.on('update', function(data) {
      self.cashDesk = data.cashDesk
    })

    self.valid = cashDeskForm.valid
    cashDeskForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/payments",
    },
    pathName: {
      type: String,
    },
    tabName: {
      type: String,
      default: 'general'
    },
    filters: {
      type: Array
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.cashDesksTab.length; i++) {
        if(this.cashDesksTab[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      cashDesksService.update(this.cashDesk).then((result) => {
        if (!!this.pathToGoBack) {
          this.close()
        }
      })
    },
    closeDialog() {
      if (this.pathToGoBack) {
        this.close()
      } else {
        this.cashDeskDialog = false
        this.$emit('update:open-dialog', false)
      }
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    fetchCashDesk() {
      this.loadingCashDesk = true
      cashDeskForm.resetCashDesk()
      cashDesksService.get(this.id).then((cashDesk) => {
        this.loadingCashDesk = false
        cashDeskForm.updateObject(cashDesk)
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.cashDeskDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.cashDesksTab[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>